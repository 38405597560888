const EventDispatcher = {
	/**
	 * Dispatches any event, taking into consideration platform specific adjustments.
	 *
	 * @param {string} eventName The name of the event to send.
	 * @param {*} [eventData] Any data you would like attached to the event.
	 *
	 * @returns {void}
	 * @function dispatch
	 * @public
	 */
	dispatch: function(eventName, eventData?) {
		let event = new CustomEvent(eventName, {detail: eventData});
		document.dispatchEvent(event);
	}
};

export default EventDispatcher;