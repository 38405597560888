import {IComponent} from "./IComponent";
import EventDispatcher from "../utils/EventDispatcher";
import ResourcePool from "../logic/ResourcePool";

class EndScreen implements IComponent {
    constructor() {
        console.log("hello end screen");
    }

    start() {
        document.getElementById("end").style.display = "block";
        document.getElementsByName("endScreenBtn").forEach(btn => {
            btn.addEventListener("click", this.onClick);
        });
    }
    onClick() {
        EventDispatcher.dispatch("END", {});
    }
    end() {
        document.getElementById("game").style.display = "none";
        document.getElementById("end").style.display = "none";
    }
}

export default EndScreen;