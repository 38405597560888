import {Game} from "./logic/Game";
import {Facade} from "./utils/Facade";

// @ts-ignore
window.mainStart = () => {
    console.log("hello");

    fetch("./assets/config/config.json").then((response) => response.json())
        .then((data) => {
            Facade.config = data;
            new Game();
        });
};