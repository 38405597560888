import ResourcePool from "../logic/ResourcePool";
import EventDispatcher from "../utils/EventDispatcher";

class Card {
    private state = false;
    private index;
    public resource;
    public graphics;

    constructor(i:number){
        this.index = i;
    }

    public start() {
        this.state = true;
        this.resource = ResourcePool.allocate();
        this.makeGraphics();
    }

    public onClick(){
        if (this.state) {
            this.graphics.classList.add("flipped");
            this.state = !this.state;

            EventDispatcher.dispatch("CARD_CLICK", {i: this.index});
        }
    }

    public disable() {
        this.state = false;
    }

    public onTimeout() {
        this.graphics.classList.remove("flipped");
        document.getElementById("card"+this.index).classList.remove("icon");
        this.state = true;
    }

    protected makeGraphics() {
        /**
         * <div class="card">
         *       <div class="content">
         *           <div class="front">
         *               <img .../>
         *           </div>
         *           <div class="back">
         *               <img .../>
         *           </div>
         *       </div>
         *   </div>
         */
        this.graphics = this.makeDiv({id: "card"+this.index, class: "card"});

        const contentDiv = this.makeDiv({class: "content"})

        const frontDiv = this.makeDiv({class: "front"});
        this.makeImage(this.resource.icon, frontDiv);

        const backDiv = this.makeDiv({class: "back"});
        this.makeImage(this.resource.back, backDiv);

        contentDiv.appendChild(frontDiv);
        contentDiv.appendChild(backDiv);
        backDiv.addEventListener("click", () => {
            this.onClick();
        }); // @todo create div of specific size depending on how many icons there are, clickable

        this.graphics.appendChild(contentDiv);
    }

    protected makeDiv(options:{id?:string, class?:string}) {
        const newDiv = document.createElement("div");
        options.id && newDiv.setAttribute("id", options.id);
        options.class && newDiv.setAttribute("class", options.class);

        return newDiv;
    }

    protected makeImage(src, parent) {
        const img = new Image();
        img.src = src;
        parent.appendChild(img);

        return img;
    }
}

export default Card;