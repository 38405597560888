import {Facade} from "../utils/Facade";

const createResources = () => {
    Facade.config.iconFacePaths.forEach((iconFacePath, index) => {
        ResourcePool.allIconsAvailable.push({
            icon: iconFacePath,
            id: index,
            back: Facade.config.iconBackPath
        })
    });
};

const ResourcePool = {
    allIconsAvailable: [],
    resources: [],
    init: (size:number) => {
        if (!ResourcePool.allIconsAvailable.length) {
            createResources();
        }

        if (size/2 > ResourcePool.allIconsAvailable.length) {
            throw new Error('Size too big');
        }

        ResourcePool.resources = [];
        const randomImageIndexes = ResourcePool.randomize(Array.apply(null, {length: ResourcePool.allIconsAvailable.length}).map(Number.call, Number));
        console.log(randomImageIndexes);
        for (let i=0; i<size/2; ++i) {
            const idx = randomImageIndexes.shift();
            ResourcePool.resources.push(ResourcePool.allIconsAvailable[idx]);
            ResourcePool.resources.push(ResourcePool.allIconsAvailable[idx]);
        }

        ResourcePool.resources = ResourcePool.randomize();
    },
    allocate: () => {
        return ResourcePool.resources.pop();
    },
    free: (res) => {
        ResourcePool.resources.push(res);
    },
    randomize: (arr?) => {
        let array = arr?.concat() ?? ResourcePool.resources.concat();
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }
}

export default ResourcePool;