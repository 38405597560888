import Board from "../components/Board";
import Lobby from "../components/Lobby";
import EndScreen from "../components/EndScreen";
import {IComponent} from "../components/IComponent";

export class Game implements IComponent {
    private componentsProto = [Lobby, Board, EndScreen]
    private components:Array<IComponent> = [];
    private currentComponent = null;

    constructor() {
        console.log("hello game");
        this.reset();
        this.init();
    }

    init() {
        document.addEventListener("END", () => {this.end();});
    }

    start() {
        this.components[this.currentComponent] = new this.componentsProto[this.currentComponent];
        this.components[this.currentComponent].start();
    }

    end() {
        this.components[this.currentComponent].end();
        this.currentComponent++;
        this.currentComponent < this.componentsProto.length ? this.start() : this.reset();
    }

    reset() {
        this.currentComponent = 0;
        this.components = [];
        this.start();
    }
};