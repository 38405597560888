import {IComponent} from "./IComponent";
import EventDispatcher from "../utils/EventDispatcher";
import {Facade} from "../utils/Facade";

class Lobby implements IComponent {
    constructor(){
        console.log("hello lobby");
    }

    start() {
        console.log("start lobby");

        document.getElementsByName("lobbyBtn").forEach(btn => {
            btn.addEventListener("click", this.lobbyOptionPressed);
        });
        document.getElementById("lobby").style.display = "block";
        document.getElementById("game").style.display = "none";
        document.getElementById("end").style.display = "none";
    }

    lobbyOptionPressed(e) {
        console.log("size:", e.target.id);
        Facade.size = Number(e.target.id);
        EventDispatcher.dispatch("END");
    }

    end() {
        document.getElementsByName("lobbyBtn").forEach(btn => {
            btn.removeEventListener("click", this.lobbyOptionPressed);
        });
        document.getElementById("lobby").style.display = "none";
    }
}

export default Lobby;