import {IComponent} from "./IComponent";
import Card from "./Card";
import ResourcePool from "../logic/ResourcePool";
import EventDispatcher from "../utils/EventDispatcher";
import {Facade} from "../utils/Facade";

class Board implements IComponent{
    private cards:Array<Card>;
    private selection:Array<number>;
    private ended;

    constructor() {
        this.cards = [];
        this.selection = [];
        this.ended = false;

        document.getElementById("board").replaceChildren();
    }

    start() {
        console.log("board start")
        document.getElementById("game").style.display = "flex";
        document.addEventListener("CARD_CLICK", (i) => {this.onClick(i);});

        ResourcePool.init(Facade.size);

        for (let i=0; i<Facade.size; ++i) {
            const card = new Card(i);
            card.start();

            this.cards.push(card);
            document.getElementById("board").appendChild(card.graphics);
        }
    }

    onClick(e) {
        // @todo cover cards with unclickable element to block further click on other cards
        this.selection.push(e.detail.i);
        if (this.selection.length === 2) {
            this.cards.forEach(card => card?.disable());
            const wasAMatch = this.match();
            this.checkEnded();
            setTimeout(() => {
                this.enableCards();
            }, wasAMatch ? 0 : Facade.config.flipBackDelay);
        }
    }

    match() {
        let ret = false;

        // @ts-ignore
        if (this.cards[this.selection[0]].resource.id === this.cards[this.selection[1]].resource.id) {
            ResourcePool.free(this.cards[this.selection[0]].resource);
            ResourcePool.free(this.cards[this.selection[1]].resource);
            this.cards[this.selection[0]] = null;
            this.cards[this.selection[1]] = null;
            ret = true;
        }

        this.selection = [];

        return ret;
    }

    checkEnded() {
        if (this.cards.filter(card => card !== null).length === 0) {
            this.ended = true;
            EventDispatcher.dispatch("END");
        }
    }

    end() {
        console.log("board end")
        document.removeEventListener("CARD_CLICK", (i) => {this.onClick(i);});
    }

    private enableCards() {
        this.cards.forEach(card => card?.onTimeout());
    }
}

export default Board